<template>
    <div class="info_box sm:pb-3">
        <div class="text-gray-500 text-left path sm:mt-6 sm:mb-4">首页-售后服务-设备绑定-设备信息</div>
        <div class="pc_show">
            <div class="table w-full">
                <div class="table_title duration-500 flex items-center w-full">
                    <div
                        v-for="(item,index) in maintenance"
                        :key="index"
                        class="h-full flex items-center duration-500"
                        style="width:20%"
                        :class="index == 0 ? 'justify-start' : index == 5 ? 'justify-end' : 'justify-center'"
                    >{{ item }}</div>
                </div>
                <ul class="w-full">
                    <li class="flex w-full duration-500 items-center" v-for="item in 4" :key="item">
                        <div class="flex justify-start items-center ma_li_item duration-500">火影T5</div>
                        <div
                            class="flex justify-center items-center ma_li_item duration-500"
                        >2021-06-07</div>
                        <div class="flex justify-center items-center ma_li_item duration-500">维修完成</div>
                        <div class="flex justify-center items-center ma_li_item duration-500">已收货</div>
                        <div class="flex justify-center items-center ma_li_item duration-500">已收货</div>
                        <div class="flex justify-end items-center ma_li_item duration-500">已收货</div>
                    </li>
                </ul>
            </div>
            <div class="flex log sm:mt-5">
                <div class="mr-12">保修/保修记录</div>
                <div>
                    <ul>
                        <li v-for="item in 4" :key="item" class="sm:mb-3">2021-06-26 电脑屏幕进行保修</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="phone_show ">
            <div class="flex justify-between items-center">
                <div class="font-blod text-base">设备信息</div>
                <div class="font-blod text-sm text-primary">一键保修/维修</div>
            </div>
            <div class="info_item">
                <div class="left">设备名称：</div>
                <div class="right">火影T5</div>
            </div>
            <div class="info_item">
                <div class="left">设备出厂：</div>
                <div class="right">深圳市火影科技有限公司</div>
            </div>
            <div class="info_item">
                <div class="left">设备保修时间：</div>
                <div class="right">5年</div>
            </div>
            <div class="info_item">
                <div class="left">积累积分：</div>
                <div class="right">122</div>
            </div>
            <div class="info_item">
                <div class="left">保修剩余时间：</div>
                <div class="right">3年</div>
            </div>
            <div class="flex info_item ">
                <div class="left">保修/保修记录：</div>
                <div>
                    <ul>
                        <li v-for="item in 4" :key="item" class="mb-2">2021-06-26 电脑屏幕进行保修</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            maintenance: ['设备名称', '设备出厂', '设备保修时间', '积累积分', '保修剩余时间', '操作'],
        };
    },
    components: {

    },
    methods: {

    },
};
</script>

<style lang='scss' scoped>
.info_box {
    margin: 0 var(--margin-base);
}
.table_title {
    background: #e8e8e8;
}
.ma_li_item {
    width: 20%;
}
.log {
    li {
        list-style-type: disc !important;
    }
}
.info_item{
    display: flex;
    align-content: center;
    margin: .625rem 0;
    .left{
        width: 30%;
        text-align: left;
    }
}
@media screen and (max-width: 640px) {
    .pc_show {
        display: none;
    }
    .phone_show {
        display: block;
    }
    .path{
        padding: .625rem 0 ;
    }
    .table {
        margin-top: 0.625rem;
        .table_title {
            height: 2.1875rem;
            font-size: 0.8125rem;
            padding: 0px 0.625rem;
        }
        ul {
            li {
                padding: 0.375rem 0.625rem;
                font-size: 0.75rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (min-width: 640px) {
     .pc_show {
        display: block;
    }
    .phone_show {
        display: none;
    }
    .table {
        margin-top: 0.625rem;
        .table_title {
            height: 2.5rem;
            padding: 0px 0.625rem;
        }
        ul {
            li {
                padding: 1.25rem 0.625rem;
                font-size: 0.875rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
</style>